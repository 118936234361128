import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper, TitleH2 } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/synthon/logosynthon.png"
import Top from "../../../images/reference/synthon/top.jpg"
import Lucie from "../../../images/reference/synthon/reaction-marketing.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Synthon extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Individuální školení Facebook Ads pro Synthon | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="synthon"
          heading="Individuální školení Facebook Ads pro Synthon"
          date="Q1 2016"
        />

        <ReferenceImageText
          right
          img={Top}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Synthon"
          link="//synthon.com/"
        >
          <p>
            Společnost Synthon byla založena v roce 1991 v Nizozemsku a zaměřuje
            se na dodávky cenově dostupných léků prostřednictvím inovací. Její
            česká pobočka v Blansku se stala základnou pro výrobu aktivních
            léčivých substancí.
          </p>
          <PaddingWrapper>
            <h2>Zadání</h2>
            <p>
              Připravit na míru školení využití Facebook reklamy primárně pro
              získávání nových zaměstnanců. Zaškolit interní tým jak v
              teoretické, tak praktické rovině.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <ReferenceImageText>
          <PaddingWrapper>
            <TitleH2>Rozsah projektu</TitleH2>
            <ul>
              <li>
                nastavení Facebook stránky, reklamního účtu a Business Manageru
              </li>
              <li>vytvoření vzorové struktury kampaní</li>
              <li>identifikace cílů a cílových skupin</li>
              <li>praktické školení</li>
              <li>zaučení pro efektivní správu kampaní přes Power Editor</li>
            </ul>
          </PaddingWrapper>
          <PaddingWrapper>
            <TitleH2>Průběh spolupráce</TitleH2>
            <p>
              Jen v Blansku pracuje pro společnost Synthon nyní více než 200
              zaměstnanců a počet neustále roste. Její HR oddělení proto hledá
              další cesty, jak oslovit nové relevantní zájemce o volné pracovní
              pozice.
            </p>
            <p>
              Náš lektor Jan Koudela připravil pro tříčlenný tým teoretickou
              prezentaci o základech komunikace na sociálních sítích a zároveň
              praktický workshop, jak pracovat s vlastní Facebook stránkou,
              vytvářet placené příspěvky a vyhodnocovat jejich úspěšnost.
            </p>
          </PaddingWrapper>
          <PaddingWrapper>
            <TitleH2>Zhodnocení zadavatele</TitleH2>
            <p>
              Oslovil nás přístup společnosti igloonet: „Naučíme vás, abyste si
              poradili sami“. Tato otevřenost, spolu s profesionálním přístupem
              a podporou nám zcela vyhovuje. První náborová kampaň následně
              předčila naše očekávání.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <Medailon personName="Lucie Loulová" position="Head of HR" img={Lucie}>
          <p>
            Otevřenost společnosti igloonet spolu s profesionálním přístupem a
            podporou nám zcela vyhovuje.
          </p>
        </Medailon>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Synthon
